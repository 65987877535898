<template>
    <b-container>
      <!-- MODAL DE CREACIÓN DE CONTACTO-->
      <b-modal v-model="confirmModal" title="Confirmación" size="sm" centered>
        <p>Está seguro que desea guardar este {{ this.message }}?</p>
        <template #modal-footer>
          <b-button variant="danger" size="sm" class="float-right" @click="confirmModal=false">
            No
          </b-button>
          <b-button variant="success" size="sm" class="float-right" @click="sendToView">
            Sí
          </b-button>
        </template>
      </b-modal>
      <b-modal v-model="modalShow" size="xl" title="Nuevo contacto" no-close-on-backdrop @close="clear()">
        <b-overlay :show="overlay" rounded="sm">
            <b-row>
              <b-col cols="12" sm="6" class="mb-3">
                <b-form-input v-model='idAlegra' id="idAlegra" type="number" placeholder="Ingrese ID Alegra" value="Ingrese ID Alegra"></b-form-input>
              </b-col>
              <b-col cols="12" sm="6" class="mb-3">
                <b-button variant="primary" class="mb-3 mr-1" @click="getDataAlegra">Consultar</b-button>
              </b-col>
            </b-row>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observerContact">
            <b-form @submit.prevent="handleSubmit(confirmModal)">
              <b-row>
                <b-col v-for="input in inputs" :key="input.index" cols="12" sm="6" class="mb-3">
                  <label class="ml-2" for="input-small">{{input.name}}</label>
                  <!-- Si es texto o numero -->
                  <ValidationProvider v-if="input.type === 'text' || input.type === 'number'" :name="input.name" :rules="input.rules" v-slot="{ errors }">
                    <b-input-group  v-if="input.type === 'text' || input.type === 'number'" size="sm">
                      <template #prepend>
                        <b-input-group-text >
                          <i :class="input.iconClass"></i>
                        </b-input-group-text>
                      </template>
                      <b-form-input :id="input.name" v-model="data[input.dataModel]" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-input-group>
                  </ValidationProvider>
                  <!-- Si es select -->
                  <ValidationProvider v-if="input.type === 'select'" :name="input.name" :rules="input.rules" v-slot="{ errors }">
                    <b-input-group v-if="input.type === 'select'" size="sm">
                      <template #prepend>
                        <b-input-group-text >
                          <i class="ri-bookmark-2-line"></i>
                        </b-input-group-text>
                      </template>
                      <b-form-select
                        :options="input.options" id="docTypeFormControl" v-model="data[input.dataModel]" :class="(errors.length > 0 ? ' is-invalid' : '')">
                        <b-form-select-option :value="null" disabled>Seleccione...</b-form-select-option>
                      </b-form-select>
                      <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-input-group>
                  </ValidationProvider>
                  <!-- Si es departamento -->
                  <ValidationProvider v-if="input.type === 'department'" :name="input.name" :rules="input.rules" v-slot="{ errors }">
                    <b-input-group v-if="input.type === 'department'" size="sm">
                      <template #prepend>
                        <b-input-group-text >
                          <i :class="input.iconClass"></i>
                        </b-input-group-text>
                      </template>
                      <b-form-select
                        :options="departments" id="departmentFormControl" v-model="department" :class="(errors.length > 0 ? ' is-invalid' : '')">
                      </b-form-select>
                      <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-input-group>
                  </ValidationProvider>
                  <!-- Si es ciudad -->
                  <ValidationProvider v-if="input.type === 'city'" :name="input.name" :rules="input.rules" v-slot="{ errors }">
                    <b-input-group v-if="input.type === 'city'" size="sm">
                      <template #prepend>
                        <b-input-group-text >
                          <i :class="input.iconClass"></i>
                        </b-input-group-text>
                      </template>
                      <b-form-select
                        :options="departments[depIndex].cities" id="cityFormControl" v-model="data[input.dataModel]" :class="(errors.length > 0 ? ' is-invalid' : '')">
                      </b-form-select>
                      <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-input-group>
                  </ValidationProvider>
                  <!-- Si es check -->
                  <ValidationProvider v-if="input.type === 'check'" :name="input.name" :rules="input.rules" v-slot="{ errors }">
                    <b-input-group v-if="input.type === 'check'" size="sm">
                      <b-form-checkbox-group
                        :options="input.options"
                        text-field="text"
                        value-field="value"
                        class="mb-3"
                        v-model="data[input.dataModel]"
                      >
                      </b-form-checkbox-group>
                      <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
          <b-button :disabled="overlay" variant="danger" size="sm" class="float-right" @click="clear()">
            No
          </b-button>
          <b-button v-if="justToFirebase" :disabled="overlay" @click="sendToView2" variant="success" size="sm" class="float-left">
            Guardar solo en Sistevot
          </b-button>
          <b-button v-else :disabled="overlay" @click="validate" variant="success" size="sm" class="float-right">
            Guardar
          </b-button>
        </template>
      </b-modal>
    </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '@/config/pluginInit'
import { db } from '@/config/firebase'
import axios from 'axios'
const basicAuth = 'Basic YXBveW9sb2dpc3RpY29waEBnbWFpbC5jb206MjI2YzFiZDk2NTM0YzczODE2ZmU='

export default {
  name: 'SimpleModal',
  props: {
    inputs: { type: Array, default: () => [] },
    colombia: { type: Array, default: () => [] },
    show: { type: Boolean, default: () => false },
    overlay: { type: Boolean, default: () => false },
    queryData: { type: Object, default: () => {} },
    crudType: { type: String, default: () => '' },
    message: { type: String, default: () => '' }
  },
  data () {
    return {
      // Ag-Grid Data
      modules: AllCommunityModules,
      data: {},
      modalShow: false,
      confirmModal: false,
      depIndex: 0,
      department: '',
      btns: false,
      cities: [],
      departments: [],
      prueba: [],
      idAlegra: '',
      justToFirebase: false,
      justUpdate: false
    }
  },
  components: {
  },
  watch: {
    crudType (newValue, oldValue) {
      if (newValue === 'update') {
        console.log('voy a conseguir la data')
        this.getData()
      }
      if (newValue === 'create') {
        console.log('voy a crear la data')
        this.createData()
      }
    },
    show (newValue, oldValue) {
      if (newValue === true) { this.modalShow = true }
      if (newValue === false) {
        this.data = {}
        this.modalShow = false
      }
    },
    department (newValue, oldValue) {
      console.log(newValue)
      this.getCities()
    }
  },
  beforeMount () {
  },
  mounted () {
    core.index()
    this.getDepartments()
  },
  methods: {
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    getCities () {
      console.log('getting citties')
      for (let index = 0; index < this.departments.length; index++) {
        if (this.departments[index].value === this.department) {
          this.depIndex = index
          this.data.addressDepartment = this.department
        }
      }
    },
    getDepartments () {
      for (let index = 0; index < this.colombia.length; index++) {
        const department = this.colombia[index]
        const cities = []
        for (let index2 = 0; index2 < department.ciudades.length; index2++) {
          cities.push({
            text: department.ciudades[index2],
            value: department.ciudades[index2]
          })
        }
        this.departments.push({
          text: department.departamento,
          value: department.departamento,
          cities: cities,
          index: index
        })
      }
    },
    createData () {
      console.log('creando la data')
      for (let index = 0; index < this.inputs.length; index++) {
        const foo = this.inputs[index].dataModel
        this.data[foo] = null
      }
      console.log(this.data.identificationObjectType)
    },
    getData () {
      console.log('consiguiendo la data', this.queryData)
      this.data = this.queryData
      this.department = this.queryData.addressDepartment
    },
    async validate () {
      const valid = await (this.$refs.observerContact.validate())
      if (valid) {
        this.confirmModal = true
      } else {
        this.makeToast('danger', 'Por favor, completa los campos requeridos')
      }
    },
    async sendToView () {
      this.confirmModal = false
      const vm = this
      await Object.keys(vm.data).forEach(function (key) {
        if (vm.data[key] === null || vm.data[key] === undefined) {
          vm.data[key] = '-'
        }
      })
      this.$emit('crudAction', { data: this.data, type: this.crudType })
    },
    async sendToView2 () {
      this.confirmModal = false
      const vm = this
      await Object.keys(vm.data).forEach(function (key) {
        if (vm.data[key] === null || vm.data[key] === undefined) {
          vm.data[key] = '-'
        }
      })
      this.$emit('crudAction', { data: this.data, type: 'dealegra' })
    },
    clear () {
      this.data = {}
      this.department = ''
      this.depIndex = 0
      this.justToFirebase = false
      this.$emit('clearModal', { clear: true })
    },
    async getDataAlegra () {
      // var isInFirebase = false
      this.justToFirebase = false
      await axios({
        method: 'get',
        url: 'https://api.alegra.com/api/v1/contacts/' + this.idAlegra,
        headers: { Authorization: basicAuth }
      }).then(res => {
        db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
          .where('identificationObject.number', '==', res.data.identification)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                console.log('Ya está', doc.id)
                this.makeToast('danger', 'El contacto ya existe en Sistevot y Alegra')
                this.clear()
                // isInFirebase = true
              })
            } else {
              this.justToFirebase = true
            }
            if (this.justToFirebase) {
              this.makeToast('success', 'Lectura Exitosa')
            }
          })
        console.log(res)
        this.data = res.data
        if (res.data.type[0] === 'client') {
          this.data.type = 'client'
        }
        if (res.data.type[0] === 'provider') {
          this.data.type = 'provider'
        }
        console.log(res.data.type[0])
        this.data.identificationObjectType = res.data.identificationObject.type
        this.data.identificationObjectDv = res.data.identificationObject.dv
        this.data.identificationObjectNumber = res.data.identification
        this.data.addressCountry = res.data.address.country
        this.data.addressDepartment = res.data.address.department
        this.department = res.data.address.department
        this.data.addressCity = res.data.address.city
        this.data.addressAdress = res.data.address.address
        // proceed = true
      }).catch((error) => {
        this.makeToast('danger', 'El contacto no se encontró en Alegra ' + error.response.data.message)
        console.log(error.response)
        this.overlay = false
      })
    }
  }
}
</script>
