<template>
  <div>
    <b-overlay :show="overlay" rounded="sm">
      <iq-card>
        <simple-modal
          @clearModal="clearModal"
          @crudAction="crudAction"
          :inputs="inputs"
          :show="modalShow"
          :queryData="dataToModal"
          :crudType="crudType"
          :message="message"
          :overlay="overlay"
          :colombia="Colombia"
        >
        </simple-modal>
        <!-- MODAL DE CONFIRMACIÓN DE BORRADO -->
        <b-modal v-model="confirmModal" title="Confirmación" size="sm" centered>
          ¿Está seguro que desea eliminar el contacto '{{contactSelected.name}}'?
          <template #modal-footer>
            <b-button variant="danger" size="sm" class="float-right ml-2" @click="confirmModal=false">
              No
            </b-button>
            <b-button variant="success" size="sm" class="float-right ml-2" @click="deleteContact">
              Sí
            </b-button>
          </template>
        </b-modal>
        <template v-slot:headerTitle>
          <h4 class="card-title">Contactos</h4>
        </template>
        <template v-slot:headerAction>
          <b-button variant="success" class="ml-2" @click="callCreationModal" >Nuevo contacto</b-button>
          <b-button variant="danger" class="ml-2" @click="confirmModal =! confirmModal" >Borrar contacto</b-button>
        </template>
        <div class="mt-10"  >
          <ag-grid-vue
            id="ag-grid"
            class="ag-theme-material border height-500"
            :enableCellTextSelection="true"
            :columnDefs="contactsHeaders"
            :gridOptions="gridOptions"
            :defaultColDef="defaultColDef"
            :rowData="contactsData"
            :floatingFilter="true"
            :modules="modules"
            rowSelection="single"
            :resizable="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            @row-selected="onContactSelected"
            @rowDoubleClicked="onRowClicked2"
            style="width: 100%; height: 70vh;"
          ></ag-grid-vue>
        <!-- <b-table :items="sale.items" ></b-table> -->
        </div>
      </iq-card>
    </b-overlay>
  </div>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import SimpleModal from './actions/SimpleModal.vue'
import { AgGridVue } from 'ag-grid-vue'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '@/config/pluginInit'
import { db } from '@/config/firebase'
import firebase from 'firebase'
import axios from 'axios'
import Colombia from '../../FackApi/api/colombia.js'
const basicAuth = 'Basic YXBveW9sb2dpc3RpY29waEBnbWFpbC5jb206MjI2YzFiZDk2NTM0YzczODE2ZmU='
export default {
  name: 'Contacts',
  data () {
    return {
      // Ag-Grid Data
      modules: AllCommunityModules,
      contactsInfo: null,
      gridApi: null,
      gridOptions: null,
      contactsData: [],
      contactsHeaders: [],
      paginationPageSize: 10,
      defaultColDef: { resizable: true },
      modalShow: false,
      confirmModal: false,
      creationModal: false,
      overlay: false,
      rowNode: {},
      oldData: {},
      data: {},
      crudType: '',
      Colombia: Colombia,
      dataToModal: {},
      contactsModel: {},
      message: 'contacto',
      inputs: [
        {
          name: 'Tipo de contacto:',
          type: 'select',
          options:
          [
            { text: 'Cliente', value: 'client' },
            { text: 'Proveedor', value: 'provider' }
          ],
          dataModel: 'type',
          rules: 'required||alpha'
        },
        {
          name: 'Tipo de doc:',
          type: 'select',
          options:
          [
            { text: 'NIT', value: 'NIT' },
            { text: 'CC', value: 'CC' }
          ],
          dataModel: 'identificationObjectType'
        },
        { name: 'Documento', type: 'number', dataModel: 'identificationObjectNumber', iconClass: 'fas fa-id-card', rules: 'required||numeric' },
        { name: 'DV', type: 'number', dataModel: 'identificationObjectDv', iconClass: 'fas fa-check', rules: 'required||numeric' },
        { name: 'ID Alegra', type: 'number', dataModel: 'id', iconClass: 'ri-bookmark-2-line' },
        { name: 'Nombre', type: 'text', dataModel: 'name', iconClass: 'fas fa-user-shield', rules: 'required' },
        { name: 'Apellido', type: 'text', dataModel: 'lastName', iconClass: 'fas fa-user-shield' },
        { name: 'Nombre del Administrador', type: 'text', dataModel: 'nameAdmin', iconClass: 'fas fa-user' },
        { name: 'Email', type: 'text', dataModel: 'email', iconClass: 'fas fa-at', rules: 'required||email' },
        { name: 'Numero de Contacto', type: 'number', dataModel: 'phonePrimary', iconClass: 'fas fa-phone-alt', rules: 'required||numeric' },
        { name: 'Pais', type: 'text', dataModel: 'addressCountry', iconClass: 'fas fa-globe-americas', rules: 'required' },
        { name: 'Departamento', type: 'department', dataModel: 'addressDepartment', iconClass: 'fas fa-flag', rules: 'required' },
        { name: 'Ciudad', type: 'city', dataModel: 'addressCity', iconClass: 'far fa-flag', rules: 'required' },
        { name: 'Direccion', type: 'text', dataModel: 'addressAdress', iconClass: 'fas fa-map-marker-alt', rules: 'required' },
        { name: 'Barrio', type: 'text', dataModel: 'addressNeigborhood', iconClass: 'fas fa-home' },
        { name: 'Sector', type: 'text', dataModel: 'addressSector', iconClass: 'ri-bookmark-2-line' }
      ],
      contactSelected: {}
    }
  },
  components: {
    AgGridVue,
    SimpleModal
  },
  beforeMount () {
    this.gridOptions = {}
    this.contactsHeaders = [
      { headerName: 'Nombre', field: 'name', sortable: true, filter: true, checkboxSelection: true, width: 500 },
      { headerName: 'Nit', field: 'nit', sortable: true, filter: true },
      { headerName: 'Telefono', field: 'phone', sortable: true, filter: true },
      { headerName: 'Email', field: 'email', sortable: true, filter: true },
      { headerName: 'Type', field: 'type', sortable: true, filter: true }
    ]
  },
  mounted () {
    core.index()
    this.getContactsIndex()
    this.clearModal()
    this.gridApi = this.gridOptions.api
  },
  firestore () {
    return {
      contactsInfo: db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF')
    }
  },
  methods: {
    clearModal () {
      console.log('limpiando')
      this.crudType = ''
      this.data = {}
      this.oldData = {}
      this.dataToModal = {}
      this.contactsModel = {
        address: {
          address: '',
          city: '',
          country: '',
          department: '',
          neighborhood: '',
          sector: ''
        },
        type: '',
        name: '',
        nameAdmin: '',
        email: '',
        phonePrimary: '',
        id: '',
        identificationObject: {
          number: '',
          type: '',
          dv: ''
        }
      }
      this.modalShow = false
    },
    // Alertas
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    getContactsIndex () {
      const vm = this
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                const data = doc.data()
                Object.values(data).forEach(function (value) {
                  vm.contactsData.push(value)
                })
              }
            })
          }
        })
      // const vm = this
      // db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex').doc('contactsIndex3')
      //   .get()
      //   .then((doc) => {
      //     if (doc.data() !== undefined) {
      //       const data = doc.data()
      //       Object.keys(data).forEach(function (key) {
      //         console.log(data[key].name, '/', key)
      //         // if (key === 'x52IsTw1YXjbv7nxM6iR') {
      //         //   console.log('si está el hpta')
      //         // } else { console.log(key) }
      //       })
      //       Object.values(data).forEach(function (value) {
      //         vm.contactsData.push(value)
      //       })
      //     }
      //   })
    },
    async onRowClicked2 (params) {
      console.log(params.node)
      this.rowNode = params.node.id
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
        .doc(params.node.data.docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.oldData = doc.data()
          } else { console.log('No data found') }
        })

      this.dataToModal = {
        type: this.oldData.type,
        identificationObjectType: this.oldData.identificationObject.type,
        identificationObjectNumber: this.oldData.identificationObject.number,
        identificationObjectDv: this.oldData.identificationObject.dv,
        id: this.oldData.id,
        name: this.oldData.name,
        lastName: this.oldData.lastName,
        nameAdmin: this.oldData.nameAdmin,
        email: this.oldData.email,
        phonePrimary: this.oldData.phonePrimary,
        addressCountry: this.oldData.address.country,
        addressDepartment: this.oldData.address.department,
        addressCity: this.oldData.address.city,
        addressAdress: this.oldData.address.address,
        addressNeigborhood: this.oldData.address.neighborhood,
        addressSector: this.oldData.address.sector
      }
      if (this.oldData.identificationObject.type === 'CC') {
        this.dataToModal.name = this.oldData.firstName
      } else { this.dataToModal.name = this.oldData.name }
      this.crudType = 'update'
      this.modalShow = true
    },
    callCreationModal () {
      this.clearModal()
      this.modalShow = true
      this.crudType = 'create'
    },
    async crudAction ({ data, type }) {
      console.log('la data es', data)
      this.data = await data
      if (this.data.identificationObjectType === 'CC') {
        this.contactsModel.firstName = this.data.name
        this.contactsModel.lastName = this.data.lastName
        this.contactsModel.name = this.data.name + ' ' + this.data.lastName
        console.log('contactsModel', this.contactsModel)
      } else {
        this.contactsModel.name = this.data.name
        console.log('contactsModel2', this.contactsModel)
      }
      this.contactsModel.address.address = this.data.addressAdress
      this.contactsModel.address.country = this.data.addressCountry
      this.contactsModel.address.city = this.data.addressCity
      this.contactsModel.address.department = this.data.addressDepartment
      this.contactsModel.address.neighborhood = this.data.addressNeigborhood
      this.contactsModel.address.sector = this.data.addressSector
      this.contactsModel.type = this.data.type
      this.contactsModel.nameAdmin = this.data.nameAdmin
      this.contactsModel.email = this.data.email
      this.contactsModel.phonePrimary = this.data.phonePrimary
      this.contactsModel.id = this.data.id
      this.contactsModel.identificationObject.number = this.data.identificationObjectNumber
      this.contactsModel.identificationObject.dv = this.data.identificationObjectDv
      this.contactsModel.identificationObject.type = this.data.identificationObjectType

      if (type === 'create') {
        this.createContact()
      }
      if (type === 'update') {
        this.updateContact()
      }
      if (type === 'dealegra') {
        this.createContactFromAlegra()
        console.log('de alegra')
      }
    },
    async createContactFromAlegra () {
      // Object.keys(this.contactsModel).forEach(function (key) {
      //   if (this.contactsModel[key] === null || this.contactsModel[key] === undefined) {
      //     this.contactsModel[key] = '-'
      //   }
      // })
      this.overlay = true
      var proceed = true
      const contactsCount = this.contactsInfo.contactsData.docsCount / 1000
      console.log(contactsCount)
      if (Number.isInteger(contactsCount)) {
        console.log('Crear nuevo indice')
        this.contactsInfo.contactsData.docsCount++
        this.contactsInfo.contactsData.indexCount++
      } else {
        console.log('es impar')
        this.contactsInfo.contactsData.docsCount++
      }
      // CREA LOS DATOS DEL CONTACTO EN CONTACT DE FIREBASE
      if (proceed) {
        db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF').update(this.contactsInfo)
          .then(() => {
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
              .add(this.contactsModel)
              .then((doc) => {
                console.log('I did it with id', doc.id)
                var newData = {
                  name: this.data.name,
                  email: this.data.email,
                  phone: this.data.phonePrimary,
                  nit: this.data.identificationObjectNumber,
                  id: this.data.id,
                  docId: doc.id,
                  index: this.contactsInfo.contactsData.indexCount,
                  type: this.data.type
                }
                db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                  .doc(`contactsIndex${this.contactsInfo.contactsData.indexCount}`)
                  .set({
                    [doc.id]: newData
                  }, { merge: true }).then((indexDoc) => {
                    this.contactsData.push(newData)
                    this.makeToast('success', 'Contacto creado en Sistevot')
                    console.log('Anadido al index')
                    this.overlay = false
                    this.modalShow = false
                  }).catch((error) => {
                    console.log('Error creando el indice', error)
                    this.makeToast('danger', 'Error al crear contacto en Sistevot. ', error)
                    this.overlay = false
                  })
              }).catch((error) => {
                console.error('Hpta la cague', error)
                this.makeToast('danger', 'Error al crear contacto en Sistevot. ', error)
                this.overlay = false
              })
          })
      }
    },
    async createContact () {
      this.overlay = true
      var proceed = false
      let name = ''
      var kindOfPerson = ''
      var dataToAlegra = {}
      dataToAlegra = {
        type: this.data.type,
        settings: {
          sendElectronicDocuments: true
        },
        statementAttached: true,
        phonePrimary: this.data.phonePrimary,
        email: this.data.email,
        ignoreRepeated: false,
        identificationObject: {
          number: this.data.identificationObjectNumber,
          type: this.data.identificationObjectType,
          dv: this.data.identificationObjectNumber,
          kindOfPerson: kindOfPerson
        },
        address: {
          address: this.data.addressAdress,
          city: this.data.addressCity,
          country: this.data.addressCountry,
          department: this.data.addressDepartment
        }
      }
      // CREA LOS DATOS DEL CONTACTO EN ALEGRA
      if (this.data.identificationObjectType === 'NIT') {
        dataToAlegra.identificationObject.kindOfPerson = 'LEGAL_ENTITY'
        dataToAlegra.name = this.data.name
        name = this.data.name
      } else {
        name = this.data.name + ' ' + this.data.lastName
        dataToAlegra.identificationObject.kindOfPerson = 'PERSON_ENTITY'
        dataToAlegra.firstName = this.data.name
        dataToAlegra.lastName = this.data.lastName
      }
      console.log('La data para alegra', dataToAlegra)
      await axios({
        method: 'post',
        url: 'https://api.alegra.com/api/v1/contacts/',
        data: dataToAlegra,
        headers: { Authorization: basicAuth }
      }).then(res => {
        console.log('La respuesta de alegra', res)
        this.data.id = res.data.id
        this.contactsModel.id = res.data.id
        proceed = true
        this.makeToast('success', 'Contacto creado en Alegra')
        // Procedimiento para validar los indicies
        const contactsCount = this.contactsInfo.contactsData.docsCount / 1000
        console.log(contactsCount)
        if (Number.isInteger(contactsCount)) {
          console.log('Crear nuevo indice')
          this.contactsInfo.contactsData.docsCount++
          this.contactsInfo.contactsData.indexCount++
        } else {
          console.log('es impar')
          this.contactsInfo.contactsData.docsCount++
        }
        // Fin rocedimiento para validar los indicies
      }).catch((error) => {
        this.makeToast('danger', 'Alegra no ha creado el contacto. ' + error.response.data.message)
        console.log('errorcito de Alegra', error.response)
        this.overlay = false
      })
      // CREA LOS DATOS DEL CONTACTO EN CONTACT DE FIREBASE
      if (proceed) {
        db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF').update(this.contactsInfo)
          .then(() => {
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
              .add(this.contactsModel)
              .then((doc) => {
                console.log('I did it with id', doc.id)
                var newData = {
                  name: name,
                  email: this.data.email,
                  phone: this.data.phonePrimary,
                  nit: this.data.identificationObjectNumber,
                  id: this.data.id,
                  docId: doc.id,
                  index: this.contactsInfo.contactsData.indexCount,
                  type: this.data.type
                }
                db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                  .doc(`contactsIndex${this.contactsInfo.contactsData.indexCount}`)
                  .set({
                    [doc.id]: newData
                  }, { merge: true }).then((indexDoc) => {
                    this.contactsData.push(newData)
                    this.makeToast('success', 'Contacto creado en Sistevot')
                    console.log('Anadido al index')
                    this.overlay = false
                    this.modalShow = false
                  }).catch((error) => {
                    console.log('Error creando el indice', error)
                    this.makeToast('danger', 'Error al crear contacto en Sistevot. ', error)
                    this.overlay = false
                  })
              }).catch((error) => {
                console.error('Hpta la cague', error)
                this.makeToast('danger', 'Error al crear contacto en Sistevot. ', error)
                this.overlay = false
              })
          })
      }
    },
    async updateContact () {
      this.overlay = true
      console.log('Estoy actualizando')
      let name = ''
      var kindOfPerson = ''
      var dataToAlegra = {}
      var proceed = false
      dataToAlegra = {
        type: this.data.type,
        settings: {
          sendElectronicDocuments: true
        },
        statementAttached: true,
        id: this.data.id,
        phonePrimary: this.data.phonePrimary,
        email: this.data.email,
        // ignoreRepeated: false,
        identificationObject: {
          number: this.data.identificationObjectNumber,
          type: this.data.identificationObjectType,
          dv: this.data.identificationObjectNumber,
          kindOfPerson: kindOfPerson
        },
        address: {
          address: this.data.addressAdress,
          city: this.data.addressCity,
          country: this.data.addressCountry,
          department: this.data.addressDepartment
        }
      }
      // CREA LOS DATOS DEL CONTACTO EN ALEGRA
      if (this.data.identificationObjectType === 'NIT') {
        dataToAlegra.identificationObject.kindOfPerson = 'LEGAL_ENTITY'
        dataToAlegra.name = this.data.name
        name = this.data.name
      } else {
        name = this.data.name + ' ' + this.data.lastName
        dataToAlegra.identificationObject.kindOfPerson = 'PERSON_ENTITY'
        dataToAlegra.firstName = this.data.name
        dataToAlegra.lastName = this.data.lastName
      }
      console.log('La data para alegra', dataToAlegra)

      await axios({
        method: 'put',
        url: 'https://api.alegra.com/api/v1/contacts/:' + this.oldData.id,
        data: dataToAlegra,
        headers: { Authorization: basicAuth }
      }).then(res => {
        console.log(res)
        proceed = true
        this.makeToast('success', 'Contacto actualizado en Alegra')
      }).catch((error) => {
        this.makeToast('danger', 'Alegra no ha actualizado el contacto. ' + error.response.data.message)
        console.log(error.response)
        this.overlay = false
      })
      if (proceed) {
        db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
          .doc(this.contactSelected.docId)
          .update(this.contactsModel)
          .then(() => {
            console.log('Lo actualicé')
            var newData = {
              name: name,
              email: this.data.email,
              phone: this.data.phonePrimary,
              nit: this.data.identificationObjectNumber,
              docId: this.contactSelected.docId,
              id: this.data.id,
              index: this.contactSelected.index,
              type: this.data.type
            }
            if (this.oldData.staff !== undefined && this.oldData.staff !== null) {
              newData.staff = this.oldData.staff
            } else { newData.staff = {} }
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
              .doc(`contactsIndex${this.contactSelected.index}`)
              .update({
                [this.contactSelected.docId]: newData
              }).then((indexDoc) => {
                console.log('Actualizado en el index')
                this.modalShow = false
                this.makeToast('success', 'Contacto actualizado en Sistevot')
                var rowNode = this.gridApi.getRowNode(this.rowNode)
                rowNode.setData(newData)
                this.gridApi.refreshCells()
                this.overlay = false
              }).catch((error) => {
                console.log('Error actualizando el indice', error)
                this.makeToast('danger', 'Error al actualizar contacto en Sistevot. ', error)
                this.overlay = false
              })
          }).catch((error) => {
            console.log('Error actualizando el documento', error)
            this.makeToast('danger', 'Error al actualizar contacto en Sistevot. ', error)
            this.overlay = false
          })
      }
    },
    async deleteContact () {
      this.overlay = true
      let proceed = false
      this.confirmModal = false
      const contactsCount = this.contactsInfo.contactsData.docsCount / 1000
      await axios({
        method: 'delete',
        url: `https://api.alegra.com/api/v1/contacts/${this.contactSelected.id}`,
        headers: { Authorization: basicAuth }
      }).then(res => {
        console.log('La respuesta de alegra', res)
        proceed = true
        this.makeToast('success', 'Contacto eliminado en Alegra')
        console.log(contactsCount)
        if (Number.isInteger(contactsCount)) {
          console.log('Borrar el indice')
          this.contactsInfo.contactsData.docsCount--
          this.contactsInfo.contactsData.indexCount--
        } else {
          console.log('es impar')
          this.contactsInfo.contactsData.docsCount--
        }
      }).catch((error) => {
        this.makeToast('danger', 'Alegra no ha eliminado el contacto. ' + error.response.data.message)
        console.log('errorcito de Alegra', error.response)
        this.overlay = false
      })
      if (proceed) {
        db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF').update(this.contactsInfo)
          .then(() => {
            db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
              .doc(this.contactSelected.docId)
              .delete()
              .then(() => {
                console.log('Document Deleted')
                db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
                  .doc(`contactsIndex${this.contactSelected.index}`)
                  .update({
                    [this.contactSelected.docId]: firebase.firestore.FieldValue.delete()
                  }).then(() => {
                    this.makeToast('success', 'Contacto eliminado en Sistevot')
                    console.log('Ya lo borre')
                    this.gridOptions.api.updateRowData({ remove: [this.contactSelected] })
                    this.overlay = false
                  }).catch((error) => {
                    this.makeToast('danger', 'Error al eliminar contacto en Sistevot. ', error)
                    this.overlay = false
                  })
              })
          })
      }
    },
    // Método por si las moscas async deleteContact () {
    //   this.overlay = true
    //   const proceed = true
    //   this.confirmModal = false
    //   const contactsCount = this.contactsInfo.contactsData.docsCount / 1000
    //   if (Number.isInteger(contactsCount)) {
    //     console.log('Borrar el indice')
    //     this.contactsInfo.contactsData.docsCount--
    //     this.contactsInfo.contactsData.indexCount--
    //   } else {
    //     console.log('es impar')
    //     this.contactsInfo.contactsData.docsCount--
    //   }
    //   if (proceed) {
    //     db.collection('campus').doc('Vs2FkUx38u3W8IDuABNF').update(this.contactsInfo)
    //       .then(() => {
    //         console.log('Document Deleted')
    //         db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
    //           .doc(`contactsIndex${this.contactSelected.index}`)
    //           .update({
    //             [this.contactSelected.docId]: firebase.firestore.FieldValue.delete()
    //           }).then(() => {
    //             this.makeToast('success', 'Contacto eliminado en Sistevot')
    //             console.log('Ya lo borre')
    //             this.gridOptions.api.updateRowData({ remove: [this.contactSelected] })
    //             this.overlay = false
    //           }).catch((error) => {
    //             this.makeToast('danger', 'Error al eliminar contacto en Sistevot. ', error)
    //             this.overlay = false
    //           })
    //       })
    //   }
    // },
    onContactSelected (event) {
      if (!event.node.selected) {
        return
      }
      console.log(event.node.data)
      this.contactSelected = event.node.data
    },
    deleteManual () {
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
        .doc('contactsIndex3')
        .update({
          Kk3xeSWhZeQKPeocxdro: firebase.firestore.FieldValue.delete()
        }).then(() => {
          console.log('Borré el doc del index')
        })
    }
  }
}
</script>
